import React from "react";
import Routes from "./Routes";
import Helmet from "helmet";
function App() {
  <Helmet>
    <title>Himalayan Natura</title>
        <link rel="icon" type="image/png" href="/logoTab.png" />
  </Helmet>
  return <Routes />;
}

export default App;
